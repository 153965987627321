@tailwind base;
@tailwind components;
@tailwind utilities;

/* font-family: 'Michroma', sans-serif;
font-family: 'Montserrat', sans-serif; */

* {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.7;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: 'eurostile', sans-serif;
  font-size: 2rem;
}

.nav-links a {
  font-family: 'europa', sans-serif;
}

.cv-icon {
  transition: all 0.2s ease-in-out;
}

.cv-icon:hover {
  transform: scale(1.1);
}

#about-section {
  height: 550px;
}

.about-me {
  font-family: 'europa', sans-serif;
  line-height: 1.8;
  font-weight: 400 !important;
  word-break: break-word;
}

.hero-wrapper {
  min-height: calc(100vh - 76.8px);
}

.hero-caption {
  font-family: 'eurostile', sans-serif;
  font-size: 2.7rem;
  color: #141c3a;
}

.hero-subtitle-size {
  font-family: 'europa', sans-serif;
  line-height: 1.5;
  font-size: 1.2rem;
  color: #0a0a0ae6;
}

.contact-subtitle {
  color: #eee !important;
}

.skills-card {
  width: 75%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: -43%;
  text-align: center;
  height: auto;
}

.skills-icons {
  flex-wrap: wrap;
  margin-top: 2rem;
  color: #111;
}

.skill-text {
  margin: 0%;
  font-family: 'europa', sans-serif;
  font-size: 0.9rem;
  color: #141c3a;
}

.skill-item {
  font-size: 3rem;
  color: rgb(51 65 85);
}

.projects-section {
  margin-top: 300px;
}

.hero-text-color {
  color: #141c3a;
}

.project-card-items {
  position: relative;
  width: 100%;
  height: 260px;
}

.project-caption {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0rem 3rem;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.45s ease-in-out;
  transition: 0.45s ease-in-out;
  z-index: 100;
  opacity: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.45s ease-in-out;
  transition: 0.45s ease-in-out;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0.5rem;
  opacity: 0;
}

/* When Project Caption/Image Hovers/Hold_Clicked then Add Opacity to Overlay */
.project-caption:hover + .overlay {
  opacity: 1;
}

.project-caption:active + .overlay {
  opacity: 1;
}

/* SHow Text */
.project-caption:hover {
  opacity: 1;
}

.project-caption:active {
  opacity: 1;
}

#footer-contact {
  margin-top: 50px;
  padding-top: 50px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(109, 40, 217, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(109, 40, 217);
}

/* Extra small devices (phones, 800px and down) */
@media only screen and (max-width: 800px) {
  #about-section {
    height: auto;
  }

  .skills-card {
    width: 100%;
    position: relative;
    text-align: center;
    overflow: auto;
    height: 100%;
  }

  .projects-section {
    margin-top: auto;
  }
}

/* Small devices (portrait tablets and large phones, 800px and up) */
@media only screen and (min-width: 800px) {
  .projects-section {
    margin-top: 400px;
  }

  .skills-card {
    width: 75%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: -73%;
    text-align: center;
    height: auto;
  }

  .hero-caption {
    font-family: 'eurostile', sans-serif;
    font-size: 2.5rem;
    color: #141c3a;
  }
}

/* Large devices (large tablets & landscape IPAD, 1025px and up) */
@media only screen and (min-width: 1025px) {
  .projects-section {
    margin-top: 350px;
  }

  .skills-card {
    width: 75%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: -68%;
    text-align: center;
    height: auto;
  }

  .hero-caption {
    font-family: 'eurostile', sans-serif;
    font-size: 2.5rem;
    color: #141c3a;
  }
}

/* Large Devices (large screen laptops, 1300 and up) */
@media (min-width: 1300px) {
  .projects-section {
    margin-top: 300px;
  }

  .skills-card {
    width: 75%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: -43%;
    text-align: center;
    height: auto;
  }
}
